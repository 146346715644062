import { Box, type BoxProps, useStyleConfig } from "@chakra-ui/react"
import { type CustomButtonSize } from "./Button";
import BundleIcon, { type BundleIconProps } from "../bundleIcon";

export type ChipProps = BoxProps & {
	size?: CustomButtonSize;
}

export type ChipIconProps = ChipProps & {
	icon: BundleIconProps['name'];
}

const Chip: React.FC<ChipProps & { variant?: string }> = ({ variant, size, children, ...rest }) => {
	const styles = useStyleConfig("Chip", { variant, size, ...rest });

	return (
		<Box __css={styles} {...rest}>
			{children}
		</Box>
	);
};

const ChipIcon: React.FC<ChipIconProps & { variant?: string }> = (
	{ variant, size, children, icon, ...rest }) => {
	const styles = useStyleConfig("Chip", { variant, size, ...rest });
	const iconSize = size === 'lg' ? '1.5em' : '1.25em';

	return (
		<Box
			display={"flex"}
			gap={"xxs"}
			__css={styles}
			{...rest}
		>
			<Box
				w={iconSize}
				h={iconSize}
				display={"flex"}
			>
				<BundleIcon
					fill={"currentColor"}
					name={icon}
					w={"100%"}
					h={"100%"}
				/>
			</Box>
			{children ? <Box>
				{children}
			</Box> : null}
		</Box>
	);
};

export { Chip, ChipIcon }
