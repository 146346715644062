import { useState } from "react";
import { Skeleton } from "@chakra-ui/react";
import { sc } from "../../Jss";


const ImageSkeletonContainer = ({ ...props }) => {
	const [isImgLoaded, setIsImgLoaded] = useState(false);

	const handleImgLoad = () => {
		setIsImgLoaded(true);
	};

	return (
		<>
			<Skeleton
				sx={{
					"--skeleton-start-color": "rgba(0, 0, 0, 0.05)",
					"--skeleton-end-color": "rgba(0, 0, 0, 0.15)",
				}}
				w="100%"
				h="100%"
				variant="pulse"
			/>
			<sc.AdvanceImage
				{...props}
				onLoad={handleImgLoad}
				opacity={isImgLoaded ? 1 : 0} transition="opacity 0.3s ease"
			/>
		</>
	)
}

export default ImageSkeletonContainer;

