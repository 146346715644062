/**
 * Used in backend to whitelist sizes
 * When sizes are changed or added, run `npm run build`
 */

/**
 * Values can be:
 * w (width)
 * h (height)
 * mw (max width)
 * mh (max height)
 * cw (crop width)
 * ch (crop height)
 */
export const imageSizes = {
	none: {
		mw: 10,
	},
	default: { //16:9
		sm: {
			cw: 384,
			ch: 216,
		},
		md: {
			cw: 768,
			ch: 432,
		},
		lg: {
			cw: 1152,
			ch: 648,
		},
		xl: {
			cw: 1440,
			ch: 810,
		}
	},
};